<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <!-- -->
    <b-row>
      <b-col>
        <div style="margin-top:39px">
          <p>Texts here are used throughout the application.</p>
        </div>
      </b-col>
    </b-row>
    <div style="height:52px;"></div>
    <b-row>
      <b-col>
        <!--- --->
        <b-table-simple
          v-if="otherData && otherData.length > 0"
          id="table-1"
          ref="table"
          hover
          small
          caption-top
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th
                v-for="data in header"
                :key="data.title"
                :class="isEditContent"
                :style="data.style"
              >
                {{ data.title }}
              </b-th>
            </b-tr>
          </b-thead>
          <!---- body --------------------------------------------- -->
          <b-tbody>
            <template v-for="(otherElement, idx) in otherData">
              <!-- <template v-for="(otherElement, pos) in otherData">
               <b-tr v-if="pos === 5" :key="otherElement.id.data + '1w21312sd'">
                <b-td :rowspan="3">
                  <h5 class="color">
                    {{ otherElement.section[0].data }}
                  </h5>
                </b-td>
                <b-td>
                  <Editor
                    v-model="otherElement.content[0].data"
                    :cus-style="curStyle"
                    :is-edit="otherElement.content[0].isEdit"
                  />
                </b-td>
              </b-tr>
              
              <b-tr v-if="pos === 5" :key="otherElement.id.data + 'sdv1'">
                <b-td>
                  <Editor
                    v-model="otherElement.content[1].data"
                    :cus-style="curStyle"
                    :is-edit="otherElement.content[1].isEdit"
                  />
                </b-td>
              </b-tr>
            
              <b-tr v-if="pos === 5" :key="otherElement.id.data + 'sdv'">
                <b-td>
                  <Editor
                    v-model="otherElement.content[2].data"
                    :cus-style="curStyle"
                    :is-edit="otherElement.content[2].isEdit"
                  />
                </b-td>
              </b-tr> 
              
               <b-tr
                v-for="(content, index) in otherElement.content"
                v-else
                :key="content.id"
              >
              -->

              <!---other data --->
              <template v-for="(content, index) in otherElement.content">
                <b-tr
                  :key="content.id"
                  :class="idx !== 1 ? '' : 'd-none'"
                >
                  <b-td v-if="index === 0" :rowspan="otherElement.content.length">
                    <h5 class="color">
                      {{ otherElement.section && otherElement.section[0].data }}
                    </h5>
                  </b-td>
                  <b-td v-if="otherElement.isDescription != null && otherElement.isDescription.data == 'SUMMARY-PAGE'">
                    <Editor
                      v-model="content.data"
                      :cus-style="curStyle"
                      :is-edit="content.isEdit"
                      :is-check-label="otherElement.checkId != null"
                      :num-index="index"
                      :num-idx="idx"
                      :is-description="otherElement.isDescription != null ? otherElement.isDescription.data : ''"
                    />
                  </b-td>
                  <template v-else-if="otherElement.isDescription != null && otherElement.isDescription.data == 'PUBLIC-RECOMMENDATION-PAGE'">
                    <b-td v-if="index <= 9">
                      <Editor
                        v-model="content.data"
                        :cus-style="curStyle"
                        :is-edit="content.isEdit"
                        :is-check-label="otherElement.checkId != null"
                        :num-index="index"
                        :num-idx="idx"
                        :is-description="otherElement.isDescription != null ? otherElement.isDescription.data : ''"
                        @isNewsletterEnabled="index === 1 ? isNewsletterEnabled($event): null"
                      />
                    </b-td>
                  </template>
                  <b-td 
                    v-else-if="otherElement.isDescription != null && otherElement.isDescription.data == 'POPUP-MESSAGE' && index === 1"
                    :class="''"
                  >
                    <Editor
                      v-model="content.data"
                      :cus-style="curStyle"
                      :is-edit="content.isEdit"
                      :is-check-label="otherElement.checkId != null"
                      :num-index="index"
                      :num-idx="idx"
                      :is-description="otherElement.isDescription != null ? otherElement.isDescription.data : ''"
                      :is-checkbox-enabled="isNewsletterCheckboxEnabled"
                    />
                  </b-td>
                  <b-td 
                    v-else-if="otherElement.isDescription == null || otherElement.isDescription.data != 'SUMMARY-PAGE'"
                    :class="idx === 3 && [0, 1, 2, 7, 12, 13].includes(index) ? 'd-none' : ''"
                  >
                    <Editor
                      v-model="content.data"
                      :cus-style="curStyle"
                      :is-edit="content.isEdit"
                      :is-check-label="otherElement.checkId != null"
                      :num-index="index"
                      :num-idx="idx"
                      :is-description="otherElement.isDescription != null ? otherElement.isDescription.data : ''"
                    />
                  </b-td>
                </b-tr>
                <template v-if="otherElement.isDescription != null && otherElement.isDescription.data == 'PUBLIC-RECOMMENDATION-PAGE' && index == 4">
                  <b-tr
                    v-for="(content1, index1) in otherElement.content.slice(-4)"
                    :key="content.id + index1"
                  >
                    <b-td>
                      <Editor
                        v-model="content1.data"
                        :cus-style="curStyle"
                        :is-edit="content1.isEdit"
                        :is-check-label="otherElement.checkId != null"
                        :num-index="index1 + 12"
                        :num-idx="idx"
                        :is-description="otherElement.isDescription != null ? otherElement.isDescription.data : ''"
                      />
                    </b-td>
                  </b-tr>
                </template>
              </template>
            </template>
            <!---- ---->
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { randomData, isArray } from "../../common/CommonUtils";
// import $ from "jquery";
import { fixHeaderTable, randomData } from "../../common/CommonUtils";
import Editor from "./Editor.vue";
const HEADERS_TABLE = [
  { title: "SECTION", style: "width: 25%", class: "" },
  { title: "CONTENT", style: "width: 75%", class: "" },
];
export default {
  components: {
    Editor,
  },
  data() {
    return {
      header: HEADERS_TABLE,
      curStyle: "color: black",
      isNewsletterCheckboxEnabled: false
    };
  },
  computed: {
    otherData: {
      get: function() {
        let data = this.$store.state.other.otherData;
        const _this = this;
        data = data.map((otherData) => {
          const otherDataClone = otherData;
          otherDataClone.content = otherData.content.map((v) => {
            v["id"] = _this.random();
            return v;
          });
          return otherDataClone;
        });
        return data;
      },
    },
    isEditContent() {
      return this.$store.state.other.editStatus
        ? "edit-th-content"
        : "normal-th-content";
    },
  },
  created() {},
  updated() {
    fixHeaderTable("table-1", "header-nav", this.$store.state.other.editStatus);
  },
  methods: {
    random() {
      return randomData(5, 2);
    },
    isNewsletterEnabled(event) {
      this.isNewsletterCheckboxEnabled = event
      console.log("newsletter", event)
    }
  },
};
</script>

<style scoped>
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.otherData-td {
  border-top: none;
}

.color {
  color: #006db6;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
}
td {
  word-break: break-word;
}
</style>
