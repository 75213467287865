<template>
  <div :class="isAdditionalContent ? 'Editor' : ''">
    <div v-if="!isEdit" :style="order">
      <div v-if="type == 'label'">
        <textarea-autosize
          v-model="applyValue"
          :dir="dir"
          :disabled="true"
          :rows="1"
          style="border: none; background: transparent; width: 100%;color: #000"
        />
      </div>
      <div v-else-if="type == 'a'">
        <a
          v-if="type == 'a'"
          :href="'https://'+presentValue"
          :style="cusStyle"
        >
          {{ presentValue }}
        </a>
      </div>
    </div>
    <div v-if="isEdit" :style="{width: isAdditionalContent && isDescription !== 'ECPS-HOMEPAGE' ? '50%' : '100%'}">
      <div v-if="editType == 'text'" class="main">
        <textarea-autosize
          v-model="applyValue"
          :autosize="true"
          :disabled="getMaxLength() == 0"
          class="form-control"
          placeholder="Add description"
          :maxlength="getMaxLength()"
          :dir="dir"
          @focusout.native="focusOut()"
        />
        <span>{{ applyValue.length }}/{{ getMaxLength() }}</span>
      </div>
    </div>
    <div v-if="isEmailFunctionRow" class="additional-container pl-2 " style="order: 1; flex-direction: column;">
      <div class="checkbox-row">
        <check-box-editor 
          v-model="checkboxValue1"
          :is-disabled="isRemoveSurvey"
          :is-edit="isEdit && userHasEmailFunctionAccess"
          @transfer="transfer1($event)"
        />
        <span>Enable email function</span>
        <div v-if="isRemoveSurvey" class="d-flex align-items-center">
          <div style="margin-top: -5px;  border: none;background-color: white; padding: 6px 12px">
            <img
              :id="`tooltip${numIndex}`"
              class="tooltip-img"
              style="border: none; width: 20px; height: 20px"
              src="@/assets/icons/tooltip.png"
            />
          </div>
          <b-tooltip
            class="btn-tooltip" :target="`tooltip${numIndex}`" triggers="hover" custom-class="custom-tooltip"
          >
            <div class="text-left">
              {{ textToolTips }}
            </div>
          </b-tooltip>
        </div>
      </div>
      <div class="checkbox-row">
        <check-box-editor
          v-model="checkboxValue2"
          :is-disabled="isRemoveSurvey || !isStoreEmail || !checkboxValue1"
          :is-edit="isEdit && userHasEmailFunctionAccess"
          @transfer="transfer2($event)"
        />
        <span>Enable newsletter function (to display email box and save email)</span>
      </div>
      <div class="checkbox-row">
        <check-box-editor
          v-model="checkboxValue"
          :is-disabled="isRemoveSurvey || !isStoreEmail || !checkboxValue1 || !checkboxValue2"
          :is-edit="isEdit && userHasEmailFunctionAccess"
          @transfer="transfer($event)"
        />
        <span>{{ checkboxLabelText }}</span>
      </div>
    </div>
    <div v-else-if="(isAdditionalContent && isDescription !== 'ECPS-HOMEPAGE')" class="additional-container pl-2 " style="order: 1;">
      <check-box-editor
        v-model="checkboxValue"
        :is-edit="isEdit && ((isDescription === 'POPUP-MESSAGE' && userHasEmailFunctionAccess) || (isDescription !== 'POPUP-MESSAGE'))" 
        :is-disabled="isDescription === 'POPUP-MESSAGE' && !isCheckboxEnabled"
        @transfer="transfer($event)"
      />
      <span>{{ checkboxLabelText }}</span>
      <div v-if="(numIndex == 1 && isDescription == 'SUMMARY-PAGE')" class="d-flex align-items-center">
        <div style="margin-top: -5px;  border: none;background-color: white; padding: 6px 12px">
          <img
            :id="`tooltip${numIndex}`"
            class="tooltip-img"
            style="border: none; width: 20px; height: 20px"
            src="@/assets/icons/tooltip.png"
          />
        </div>
        <b-tooltip
          class="btn-tooltip" :target="`tooltip${numIndex}`" triggers="hover" custom-class="custom-tooltip"
        >
          <div class="text-left">
            {{ textToolTips }}
          </div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { isArray } from '../../common/CommonUtils';
import {
ROLE_STANDARD
} from "../../constants/UserRole";
import CheckBoxEditor from "../questionnaire/CheckBoxEditor";
export default {
  name: "Editor",
  components: {CheckBoxEditor},
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    referenceValue: {
      type: Object,
      default: () => { return {} }
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isCheckLabel: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: String,
      default: "text",
    },
    numIndex: {
      type: Number,
      default: 0,
    },
    numIdx: {
      type: Number,
      default: 0,
    },
    isDescription: {
      type: String,
      default: "text",
    },

    type: {
      type: String,
      default: "label",
    },
    cusStyle: {
      type: String,
      default: "color: black;word-break: break-word",
    },
    isCheckboxEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const {code, name} = this.$store.state.survey.language;
    const isArabic = code === "ar" && name === "Arabic";
    return {
      mutateValue: null,
      presentValue: null,
      isAdditionalContent: false,
      checkboxValue: false,
      checkboxLabelText: null,
      checkboxValue1: false,
      checkboxValue2: false,
      textToolTips: "To activate this function, please contact global marketing.",
      dir: isArabic ? "rtl" : "ltr",
      pClass: isArabic ? "text-right" : "text-left",
      order: isArabic ? "order: 2" : "order: 1",
      isEmailFunctionRow: this.numIdx === 5 && this.numIndex === 1
    };
  },
  computed: {
    isRemoveSurvey: {
      get: function() {
        return this.$store.state.other.isRemoveSurveyStatus
      }
    },
    isStoreEmail: {
      get: function() {
        return this.$store.state.other.isStoreEmail
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    userHasEmailFunctionAccess() {
      return this.currentUser.role !== ROLE_STANDARD
    },
    applyValue: {
      get: function () {
        if (this.isAdditionalContent) {
          const {text} = this.extractValue(this.mutateValue)
          return text
        } else return this.mutateValue;
      },
      set: function (newValue) {
        if (this.isAdditionalContent) {
          this.mutateValue = `${newValue}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
          if (this.isEmailFunctionRow) {
            this.mutateValue +=  `{1${this.checkboxValue1.toString()}}{2${this.checkboxValue2.toString()}}` 
          }
        } else {
          this.mutateValue = newValue;
        }
        this.presentValue = this.createPresentValue(newValue)
      },
    },
  },
  watch: {
    checkboxValue: function () {
      if (this.isAdditionalContent) {
        const {text} = this.extractValue(this.mutateValue, !this.checkboxValue)
        this.mutateValue = `${text}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
        if (this.isEmailFunctionRow) {
          this.mutateValue +=  `{1${this.checkboxValue1.toString()}}{2${this.checkboxValue2.toString()}}` 
        }
        this.focusOut()
      }
      return
    },
    checkboxValue1: function () {
      if (this.isAdditionalContent) {
        const {text} = this.extractValue(this.mutateValue)
        this.mutateValue = `${text}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
        if (this.isEmailFunctionRow) {
          this.mutateValue +=  `{1${this.checkboxValue1.toString()}}{2${this.checkboxValue2.toString()}}` 
        }
        this.focusOut()
        this.$emit("isNewsletterEnabled", !this.isRemoveSurvey && this.isStoreEmail && this.checkboxValue1 && this.checkboxValue2);
      }
      return
    },
    checkboxValue2: function () {
      if (this.isAdditionalContent) {
        const {text} = this.extractValue(this.mutateValue)
        this.mutateValue = `${text}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
        if (this.isEmailFunctionRow) {
          this.mutateValue +=  `{1${this.checkboxValue1.toString()}}{2${this.checkboxValue2.toString()}}` 
        }
        this.focusOut()
        this.$emit("isNewsletterEnabled", !this.isRemoveSurvey && this.isStoreEmail && this.checkboxValue1 && this.checkboxValue2);
      }
      return
    }
  },
  created() {
    this.mutateValue = this.value
    if (this.checkCheckboxSegment()) {
      const {text} = this.extractValue(this.value)
      this.presentValue = this.createPresentValue(text)
    } else {
      this.presentValue = this.createPresentValue(this.value)
    }
  },
  methods: {
    transfer(event) {
      this.checkboxValue = event;
    },
    transfer1(event) {
      this.checkboxValue1 = event;
    },
    transfer2(event) {
      this.checkboxValue2 = event;
    },
    focusOut() {
      this.$emit("input", this.isAdditionalContent ? this.mutateValue : this.applyValue);
    },
    extractValue(text, bool = null) {
      const splitSeg = bool !== null ? bool : this.checkboxValue.toString()
      const splitValue = text.split(`{${splitSeg}}`)
      return {
        text: splitValue[0],
        label: splitValue[1] || ''
      }
    },
    createPresentValue(text) {
      return /\r|\n/.test(text) ? text.split('\n') : text
    },
    handleInput() {
      this.$emit("input", this.applyValue);
    },
    isArray(data){
      return isArray(data)
    },
    checkCheckboxSegment() {
      if (!this.value.includes('{true}') && !this.value.includes('{false}')) {
        return false
      }

      this.isAdditionalContent = true
      this.checkboxValue = this.value.includes('{true}')
      if (this.isEmailFunctionRow) {
        this.checkboxLabelText = 'Check this box to automatically agree to email consent.'
        this.checkboxValue1 = this.value.includes('{1true}')
        this.checkboxValue2 = this.value.includes('{2true}')
      } else {
        const {label} = this.extractValue(this.value)
        this.checkboxLabelText = label
      }
      return true
    },
    getMaxLength() {
      let maxlength = 1;
      switch (this.numIdx) {
        // Public homepage
        case 0:
          if(this.numIndex === 0) maxlength = 65;
          if(this.numIndex === 1) maxlength = 145;
          if(this.numIndex === 2) maxlength = 15;
          break;
        // Login
        case 1:
          maxlength = 0;
          break;
        // Ecp's Homepage
        case 2:
          if(this.numIndex === 0) maxlength = 20;
          if(this.numIndex === 1 || this.numIndex === 2) maxlength = 25;
          break;
        // Login page
        case 3:
          if(
            this.numIndex === 0
            || this.numIndex === 1
            || this.numIndex === 2
            || this.numIndex === 7
            || this.numIndex === 12
            || this.numIndex === 13
          ) maxlength = 0;
          if(this.numIndex === 3) maxlength = 32;
          if(this.numIndex === 4) maxlength = 30;
          if(this.numIndex === 5 || this.numIndex === 6) maxlength = 40;
          if(this.numIndex === 8) maxlength = 28;
          if(this.numIndex === 9) maxlength = 15;
          if(this.numIndex === 10 || this.numIndex === 11) maxlength = 40;
          if(this.numIndex === 14 || this.numIndex === 15) maxlength = 20;
          if(this.numIndex === 16) maxlength = 14;
          if(this.numIndex === 17) maxlength = 8;
          break;
        // Packages
        case 4:
          maxlength = 22;
          break;
        // Public Recommendation page
        case 5:
          if(this.numIndex === 0) maxlength = 34;
          if(this.numIndex === 1) maxlength = 48;
          if(this.numIndex === 2 || this.numIndex === 3) maxlength = 100;
          if(this.numIndex === 4) maxlength = 150;
          if(this.numIndex === 5) maxlength = 15;
          if(this.numIndex === 6) maxlength = 34;
          if(this.numIndex === 7) maxlength = 22;
          if(this.numIndex === 8) maxlength = 110;
          if(this.numIndex === 9) maxlength = 37;
          if(this.numIndex === 12) maxlength = 37;
          if(this.numIndex === 13) maxlength = 90;
          if(this.numIndex === 14) maxlength = 90;
          if(this.numIndex === 15) maxlength = 37;
          break;
        // ECP's Recommendation page
        case 6:
          if(this.numIndex === 0) maxlength = 32;
          if(this.numIndex === 1) maxlength = 26;
          if(this.numIndex === 2 || this.numIndex === 3) maxlength = 22;
          break;
          // ECP's edit Recommendation page
        case 7:
          if(this.numIndex === 0) maxlength = 28;
          if(this.numIndex === 1) maxlength = 13;
          if(this.numIndex === 2) maxlength = 15;
          if(this.numIndex === 3) maxlength = 42;
          if(this.numIndex === 4) maxlength = 160;
          if(this.numIndex === 5) maxlength = 40;
          break;
        // Prescription page
        case 8:
          if(this.numIndex === 0) maxlength = 14;
          if(this.numIndex === 1) maxlength = 70;
          if(this.numIndex === 2) maxlength = 15;
          if(this.numIndex === 3) maxlength = 58;
          break;
        // Questionnaire edit page
        case 9:
          if(this.numIndex === 0) maxlength = 15;
          if(this.numIndex === 1) maxlength = 13;
          break;
        // Compare Lens page
        case 10:
          if(this.numIndex === 0) maxlength = 15;
          if(this.numIndex === 1) maxlength = 23;
          if(this.numIndex === 2) maxlength = 37;
          if(this.numIndex === 3) maxlength = 25;
          if(this.numIndex === 4) maxlength = 17;
          if(this.numIndex === 5) maxlength = 19;
          if(this.numIndex === 6) maxlength = 8;
          if(this.numIndex === 7) maxlength = 42;
          if(this.numIndex === 8) maxlength = 28;
          if(this.numIndex === 9 || this.numIndex === 10) maxlength = 25;
          if(this.numIndex === 11) maxlength = 59;
          break;
        // Lens Simulation page
        case 11:
          if(this.numIndex === 0) maxlength = 24;
          if(this.numIndex === 1) maxlength = 15;
          if(this.numIndex === 2) maxlength = 8;
          if(this.numIndex === 3) maxlength = 10;
          if(this.numIndex === 4) maxlength = 34;
          if(this.numIndex === 5) maxlength = 42;
          break;
        // Lens Confirmation page
        case 12:
          if(this.numIndex === 0) maxlength = 42;
          if(this.numIndex === 1) maxlength = 56;
          if(this.numIndex === 2) maxlength = 15;
          if(this.numIndex === 3) maxlength = 42;
          break;
        // Summary page
        case 13:
          if(this.numIndex === 0) maxlength = 20;
          if(this.numIndex === 1 || this.numIndex === 2 || this.numIndex === 3) maxlength = 28;
          if(this.numIndex === 4) maxlength = 44;
          if(this.numIndex === 5) maxlength = 42;
          if(this.numIndex === 6) maxlength = 21;
          if(this.numIndex === 7) maxlength = 29;
          if(this.numIndex === 8) maxlength = 21;
          if(this.numIndex === 9) maxlength = 29;
          if(this.numIndex === 10) maxlength = 21;
          break;
        // Product summary, pop-up mesasge
        case 14:
          if(this.numIndex === 0) maxlength = 55;
          if(this.numIndex === 1 || this.numIndex === 2) maxlength = 20;
          break;
        // Pop-up messages
        case 15:
          if(this.numIndex === 0) maxlength = 40;
          if(this.numIndex === 1) maxlength = 140;
          if(this.numIndex === 2 || this.numIndex === 3) maxlength = 30;
          if(this.numIndex === 4) maxlength = 150;
          if(this.numIndex === 5) maxlength = 59;
          if(this.numIndex === 6) maxlength = 54;
          if(this.numIndex === 7 || this.numIndex === 8) maxlength = 8;
          if(this.numIndex === 9) maxlength = 50;
          break;
        default:
          maxlength = 15;
          break;
      }
      return maxlength;
    }
  },
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
  background-color: transparent;
  color: #321fdb !important;
}
textarea {
  resize: none;
}

.main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
}

.main.isRemoveSurvey {
  background-color: #d8dbe0!important;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}

.Editor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .additional-container {
      display: flex;

      .checkbox-row {
        display: flex;
      }
    }
  
}


</style>
